import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Link } from 'gatsby';
import IconAlert from '../images/alert-icon.svg'
import IconSimpleInstallFlow from '../images/simple-install-flow-icon.svg'
import IconAppTransformer from '../images/app-transformer-icon.svg'
import IconWebhooks from '../images/webhook-icon.svg'
import IconPartner  from '../images/partner-icon.svg'
import IconChangeEvents from '../images/change-events-icon.svg'

export default class IntegrationDeveloper extends React.Component {

        
    render() {
    
        return(
        <Layout>
            <SEO title="Integration Developer" />
            <section>
                <div className="md:h-3lane sm:2h-lane bg-gray-dark text-center text-4xl text-white content-center justify-center grid"> 
                Integration Developer
                </div>
            </section>
            <section className="bg-gray-xxl">
                <div className="grid py-3gut sm:flex sm:items-center justify-center">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-20 ">
                    <a href="/docs/ZG9jOjExMDI5NTgx-sending-an-alert-event">
                    <div className="max-w-sm bg-white hover:bg-gray-100 rounded overflow-hidden shadow-lg h-3lane w-3lane p-14 border border-gray-xlight">
                        <img className="h-5gut ml-4gut my-gut" src={IconAlert}/>
                            <div className="p-10">
                                <div className="font-bold text-center mb-2">Alert Events</div>
                                <p className="text-gray-700 text-sm">
                                Trigger, acknowledge and resolve  incidents on a service in PagerDuty. 
                                </p>
                            </div>
                    </div>
                    </a>
                    <a href="/docs/ZG9jOjExMDI5NTgy-sending-a-change-event">
                    <div className="max-w-sm bg-white hover:bg-gray-100 rounded overflow-hidden shadow-lg h-3lane w-3lane p-14 border border-gray-xlight">
                        <img className="h-6gut ml-4gut mt-gut" src={IconChangeEvents}/>
                            <div className="p-10">
                                <div className="font-bold text-center mb-2">Change Events</div>
                                <p className="text-gray-700 text-sm">
                                Represent service changes and provide crucial information for incident triage.
                                </p>
                            </div>
                    </div>
                    </a>
                    <a href="/docs/ZG9jOjExNTQzMjI5-events-integration-functionality#simple-install-flow-optional-but-recommended">
                    <div className="max-w-sm bg-white hover:bg-gray-100 rounded overflow-hidden shadow-lg h-3lane w-3lane p-14 border border-gray-xlight">
                        <img className="h-5gut ml-4gut my-gut" src={IconSimpleInstallFlow}/>
                            <div className="px-10">
                                <div className="font-bold text-center mb-2">Simple Install Flow</div>
                                <p className="text-gray-700 text-sm">
                                Provides an interface for installing an integration directly from your application. Similar to OAuth!
                                </p>
                            </div>
                    </div>
                    </a>
                    <a href="/docs/ZG9jOjExNTQzMjMw-writing-app-event-transformers">
                    <div className="max-w-sm bg-white hover:bg-gray-100 rounded overflow-hidden shadow-lg h-3lane w-3lane p-14 border border-gray-xlight">
                            <img className="h-5gut ml-4gut my-gut" src={IconAppTransformer}/>
                            <div className=" px-10">
                                <div className="font-bold text-center mb-2">App Event Transformer</div>
                                <p className="text-gray-700 text-sm">
                                Use JavaScript code (ES6) to convert a payload sent to PagerDuty into the Events API v2 Common Event Format.
                                </p>
                            </div>
                    </div>
                    </a>
                    <a href="/docs/ZG9jOjQ1MTg4ODQ0-overview">
                    <div className="max-w-sm bg-white hover:bg-gray-100 rounded overflow-hidden shadow-lg h-3lane w-3lane p-14 border border-gray-xlight">
                            <img className="h-5gut ml-4gut my-gut" src={IconWebhooks}/>
                            <div className="p-10">
                                <div className="font-bold text-center mb-2">Webhooks</div>
                                <p className="text-gray-700 text-sm">
                                Subscribe to get notified of events that occur in PagerDuty.
                                </p>
                            </div>
                    </div>
                    </a>
                    <a href="/docs/ZG9jOjExMDI5NTc2-publish-an-app">
                    <div className="max-w-sm bg-white hover:bg-gray-100 rounded overflow-hidden shadow-lg h-3lane w-3lane p-14 border border-gray-xlight">
                            <img className="h-5gut ml-4gut my-gut" src={IconPartner}/>
                            <div className="p-10">
                                <div className="font-bold text-center mb-2">Publish an Integration</div>
                                <p className="text-gray-700 text-sm">
                                Let other PagerDuty customers know about your integration! 
                                </p>
                            </div>
                    </div>
                    </a>
                    <a href="/docs/ZG9jOjExMDI5NTcz-o-auth-2-0-functionality">
                    <div className="max-w-sm bg-white hover:bg-gray-100 rounded overflow-hidden shadow-xl h-3lane w-3lane p-14 border border-gray-xlight">
                        <img className="h-5gut ml-4gut my-gut" src={IconSimpleInstallFlow}/>
                            <div className="p-10">
                                <div className="font-bold text-center mb-2">OAuth</div>
                                <p className="text-gray-700 text-sm">
                                Connect to our REST API as a PagerDuty user.
                                </p>
                            </div>
                    </div>
                    </a>
                </div>
                </div>
            </section>
        </Layout>
        )
    };

};

